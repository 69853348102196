<template>
    <div class="app-container">
       <img src="../../assets/images/welcome.png" style="width:430px;margin-top:216px;"/> 
       <div class="text">欢迎使用天一纪念馆展屏管理系统~</div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
            }
        },
        components: {
            
        },
        created() {
        },
        methods: {
        }
    }
</script>
<style lang="scss" scoped>
.app-container{
    width: 100%;
    text-align: center;
    .text{
        font-size: 30px;
        font-weight: bold;
        color: #000000;
        margin-top: 47px;
    }
}
</style>
