<template>
  <el-dialog
    :title="!dataForm.gradeId ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px" size="small">
      <el-form-item label="类型" prop="type">
        <el-radio-group v-model="dataForm.type">
          <el-radio label="0">学级</el-radio>
          <el-radio label="1">年份</el-radio>
          <el-radio label="2">班级</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="dataForm.typeList[dataForm.type] + '名称'" prop="name">
        <el-input v-model="dataForm.name" :placeholder="dataForm.typeList[dataForm.type] + '名称'"></el-input>
      </el-form-item>
      <el-form-item label="上级名称" prop="parentName">
        <el-popover
          ref="gradeListPopover"
          placement="bottom-start"
          trigger="click">
          <el-tree
            :data="gradeList"
            :props="gradeListTreeProps"
            node-key="gradeId"
            ref="gradeListTree"
            @current-change="gradeListTreeCurrentChangeHandle"
            :default-expand-all="false"
            :highlight-current="true"
            :expand-on-click-node="false">
          </el-tree>
        </el-popover>
        <el-input v-model="dataForm.parentName" v-popover:gradeListPopover :readonly="true" placeholder="点击选择上级" class="menu-list__input"></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input-number v-model="dataForm.sort" controls-position="right" :min="0" label="排序"></el-input-number>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { treeDataTranslate } from "@/utils";
import { gradeSelect, gradeDataFormSubmit, info } from '@/api/modules/grade';
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        gradeId: 0,
        type: 0,
        typeList: ["学级", "年份", "班级"],
        name: "",
        parentId: 0,
        parentName: "",
        sort: 0,
      },
      dataRule: {
        name: [
          { required: true, message: "名称不能为空", trigger: "blur" }
        ],
        parentId: [
          { required: true, message: "上级不能为空", trigger: "change" }
        ]
      },
      gradeList: [],
      gradeListTreeProps: {
        label: "name",
        children: "children"
      }
    };
  },
  created() {
  },
  methods: {
    async init(id) {
      this.dataForm.gradeId = id;
      const { data } = await gradeSelect();
      this.gradeList = treeDataTranslate(data.gradeList, "gradeId");
      this.visible = true;
      await this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        info({id:this.dataForm.gradeId}).then(({data}) => {
          if (data && data.code === 0) {
            this.dataForm.name = data.grade.name
            this.dataForm.type = data.grade.type
            this.dataForm.parentId = data.grade.parentId
            this.dataForm.sort = data.grade.sort
          }
        })
      });
      this.menuListTreeSetCurrentNode();
    },
    // 菜单树选中
    gradeListTreeCurrentChangeHandle(data, node) {
      this.dataForm.parentId = data.gradeId;
      this.dataForm.parentName = data.name;
    },
    // 菜单树设置当前选中节点
    menuListTreeSetCurrentNode() {
      this.$refs.gradeListTree.setCurrentKey(this.dataForm.parentId);
      this.dataForm.parentName = (this.$refs.gradeListTree.getCurrentNode() ||
        {})["name"];
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid) {
          const params = {
            gradeId: this.dataForm.gradeId,
            type: this.dataForm.type,
            name: this.dataForm.name,
            parentId: this.dataForm.parentId,
            sort: this.dataForm.sort
          };
          const { data } = await gradeDataFormSubmit(
            `/grade/grade/${!this.dataForm.gradeId ? "save" : "update"}`,
            params
          );
          if (data && data.code === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.$emit("refreshDataList");
              }
            });
          } else {
            this.$message.error(data.msg);
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
.mod-menu {
  .menu-list__input,
  .icon-list__input {
    > .el-input__inner {
      cursor: pointer;
    }
  }
  &__icon-popover {
    max-width: 370px;
  }
  &__icon-list {
    max-height: 240px;
    padding: 0;
    margin: -8px 0 0 -8px;
    > .el-button {
      padding: 8px;
      margin: 8px 0 0 8px;
      > span {
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 18px;
        font-size: 18px;
      }
    }
  }
  .icon-list__tips {
    font-size: 18px;
    text-align: center;
    color: #e6a23c;
    cursor: pointer;
  }
}
</style>
