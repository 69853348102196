<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="姓名" prop="name">
        <el-input v-model="dataForm.name" placeholder="姓名"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="荣誉名称" prop="honorName">
        <el-input v-model="dataForm.honorName" placeholder="荣誉名称"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select v-model="dataForm.type" size="small" placeholder="请选择类型" style="width: 100%">
          <el-option label="老师" value="0" size="small">老师</el-option>
          <el-option label="学生" value="1" size="small">学生</el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="dataForm.type==0" label="荣誉类型" prop="honorType">
        <el-select v-model="dataForm.honorType" size="small" placeholder="请选择荣誉类型" style="width: 100%">
          <el-option label="奖励性荣誉" value="0" size="small">奖励性荣誉</el-option>
          <el-option label="学术性荣誉" value="1" size="small">学术性荣誉</el-option>
          <el-option label="赛课获奖者" value="2" size="small">赛课获奖者</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="照片" prop="picUrl">
        <el-upload
          style="display: inline-block; margin-right: 10px"
          class="avatar-uploader"
          :action="action"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          >
          <div v-if="dataForm.picUrl">
            <i v-if="dataForm.picUrl" color="#000"></i>
            <img
              v-if="
                dataForm.picUrl.toString().indexOf('.mp4') == -1 &&
                dataForm.picUrl.toString().indexOf('.MP4') == -1
              "
              :src="dataForm.picUrl"
              class="avatar"
            />
            <video
              v-else
              controls="controls"
              :src="dataForm.picUrl"
              style="width: 178px; height: auto"
            />
          </div>
          <i v-else class="el-icon-plus avatar-uploader-icon" title="点击上传" ></i>
        </el-upload>
        <div>
          <span>支持jpg,png ，尺寸285*200，文件小于500KB</span>
        </div>
      </el-form-item>
      <!-- <el-form-item label="介绍" prop="summary">
        <el-input v-model="dataForm.summary" placeholder="介绍"  size="small"></el-input>
      </el-form-item> -->
      <el-form-item label="介绍"  prop="summary">
	      <tinymce ref="tinymce" style="width:100%;" v-model="dataForm.summary" @input="getContent"/>
	  </el-form-item>
      <el-form-item label="年份" prop="yearNum">
        <el-input v-model="dataForm.yearNum" placeholder="年份"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input v-model="dataForm.sort" placeholder="排序"  size="small"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/honor'
  import {getToken} from '@/utils/auth'
  import Tinymce from "@/components/Tinymce"

  export default {
    data () {
      return {
        action: `${process.env.VUE_APP_BASE_API}/sys/oss/upload?token=${getToken()}`,
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
          name: '',
          honorName: '',
          type: '',
          honorType: '',
          picUrl: '',
          summary: '',
          yearNum: '',
          sort: ''
        },
        dataRule: {
          name: [
            { required: true, message: '姓名不能为空', trigger: 'blur' }
          ],
          honorName: [
            { required: true, message: '荣誉名称不能为空', trigger: 'blur' }
          ],
          type: [
            { required: true, message: '类型不能为空', trigger: 'blur' }
          ],
          sort: [
            { required: true, message: '排序不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    components: {
      Tinymce
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.name = data.honor.name
                this.dataForm.honorName = data.honor.honorName
                this.dataForm.type = data.honor.type
                this.dataForm.picUrl = data.honor.picUrl
                this.dataForm.summary = data.honor.summary
                this.dataForm.yearNum = data.honor.yearNum
                this.dataForm.sort = data.honor.sort
                this.dataForm.honorType = data.honor.honorType
                this.$refs.tinymce.setContent(this.dataForm.summary);
              }
            })
          } else {
            this.$refs.tinymce.setContent(this.dataForm.summary);
          }
        })
      },
      handleAvatarSuccess(res, file, type) {
        if (res && res.code === 0) {
          this.dataForm.picUrl = res.data.fileUrl
        } else {
            this.$message.error(res.msg);
        }
      },
      beforeAvatarUpload(file) {
        const isJPG =
            file.type === "image/jpeg" ||
            file.type === "image/bmp" ||
            file.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isJPG) {
            this.$message.error("上传格式不支持!");
        }
        if (!isLt2M) {
            this.$message.error("上传文件大小不能超过 5MB!");
        }
        return isJPG && isLt2M;
      },
      getContent(content) {
	      this.dataForm.summary = content;
	    },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'name': this.dataForm.name,
              'honorName': this.dataForm.honorName,
              'type': this.dataForm.type,
              'picUrl': this.dataForm.picUrl,
              'summary': this.dataForm.summary,
              'yearNum': this.dataForm.yearNum,
              'sort': this.dataForm.sort,
              'honorType': this.dataForm.honorType
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
<style>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        display: block;
    }
    .avatar-uploader .el-upload-dragger{
      width: 178px;
      height: 178px;
    }
</style>
