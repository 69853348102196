import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/grade/grade/list',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/grade/grade/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/grade/grade/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/grade/grade/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/grade/grade/delete',
    method: 'post',
    data: params
  })
}


export function gradeSelect() {
  return request({
    url: '/grade/grade/select',
    method: 'get'
  })
}

export function queryGradeList (params) {
  return request({
    url: '/grade/grade/queryGradeList',
    method: 'get',
    params: params
  })
}


// 菜单管理API
export function gradeInfo(url) {
  return request({
    url,
    method: 'get'
  })
}

export function gradeDataFormSubmit(url, data) {
  return request({
    url,
    method: 'post',
    data
  })
}

export function gradeDelete(url) {
  return request({
    url,
    method: 'post'
  })
}
