<template>
  <div class="login-container">
    <el-form
      class="login-form"
      autocomplete="on"
      :model="loginForm"
      :rules="loginRules"
      ref="loginForm"
      label-position="left"
    >
      <h3 class="title">天一纪念馆展屏管理系统</h3>
      <el-form-item prop="username">
        <span class="svg-container svg-container_login">
          <svg-icon icon-class="user"/>
        </span>
        <el-input
          name="username"
          type="text"
          v-model="loginForm.username"
          autocomplete="on"
          placeholder="请输入用户名"
        />
      </el-form-item>

      <el-form-item prop="password">
        <span class="svg-container">
          <svg-icon icon-class="password"/>
        </span>
        <el-input
          name="password"
          :type="passwordType"
          v-model="loginForm.password"
          autocomplete="on"
          placeholder="请输入密码"
        />
        <span class="show-pwd" @click="showPwd">
          <!-- <svg-icon icon-class="eye"/> -->
        </span>
      </el-form-item>

      <!-- <el-form-item prop="captcha">
        <span class="svg-container">
          <span class="el-icon-picture"/>
        </span>
        <el-input
          name="captcha"
          type="text"
          @keyup.enter.native="handleLogin"
          v-model="loginForm.captcha"
          autocomplete="on"
          placeholder="请输入验证码"
        />
        <img :src="imgUrl" @click="vCode" class="vcode">
      </el-form-item> -->
      <el-button
        type="primary"
        style="width:100%;margin-bottom:30px;"
        :loading="loading"
        @click.native.prevent="handleLogin"
      >登 录</el-button>
      <!-- <div class="tips">
        <span style="margin-right:20px;">username: admin</span>
        <span> password: admin</span>
      </div>-->
    </el-form>
  </div>
</template>

<script>
import { isvalidUsername, guid } from "@/utils/validate";
import { Config } from "@/utils/config";
import { Message } from "element-ui";

export default {
  name: "login",
  data() {
    // const validateUsername = (rule, value, callback) => {
    //   if (value) {//isvalidUsername
    //     callback(new Error("请输入正确的用户名"));
    //   } else {
    //     callback();
    //   }
    // };
    const validatePassword = (rule, value, callback) => {
      if (value.length < 4) {
        callback(new Error("密码不能少于4位数"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: "",
        password: "",
        uuid: "",
        captcha: ""
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "请输入用户名" } //validator: validateUsername
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword }
        ],
        captcha: [{ required: true, trigger: "blur", message: "请输入验证码" }]
      },
      passwordType: "password",
      loading: false,
      showDialog: false,

      imgUrl: ""
    };
  },
  created() {
    this.loginForm.uuid = guid();
    this.imgUrl = `${process.env.BASE_API}/captcha.jpg?uuid=${
      this.loginForm.uuid
    }`;
  },
  methods: {
    showPwd() {
      if (this.pwdType === "password") {
        this.pwdType = "";
      } else {
        this.pwdType = "password";
      }
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch("Login", this.loginForm)
            .then(res => {
              if (!res.code) {
                this.$router.push({ path: "/" });
              } else {
                Message.error(res.msg);
              }
              this.loading = false;
              this.vCode();
            })
            .catch(err => {
              Message.error(err);
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 点击更新验证码
    vCode() {
      const uuid = guid();
      this.loginForm.uuid = uuid;
      this.imgUrl = `${process.env.BASE_API}/captcha.jpg?uuid=${uuid}`;
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
$bg: #2d3a4b;
$light_gray: #eee;

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;
    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: #fff !important;
      }
    }
  }
  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
.login-container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: $bg;
  .login-form {
    position: absolute;
    left: 0;
    right: 0;
    width: 520px;
    padding: 35px 35px 15px 35px;
    margin: 120px auto;
  }
  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
    &_login {
      font-size: 20px;
    }
  }
  .title {
    font-size: 26px;
    font-weight: 400;
    color: $light_gray;
    margin: 0px auto 40px auto;
    text-align: center;
    font-weight: bold;
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
.vcode {
  position: absolute;
  height: 30px;
  top: 9px;
  right: 10px;
}
</style>