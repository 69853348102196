<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="留言人" prop="userName">
        <el-input v-model="dataForm.userName" placeholder="留言人"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="内容" prop="content">
        <m-audio v-if="dataForm.type=='0'" :src="dataForm.content"/>
        <el-input v-if="dataForm.type=='1'" v-model="dataForm.content" placeholder="内容"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select v-model="dataForm.type" size="small" placeholder="请选择类型" style="width: 100%">
          <el-option label="语音" value="0" size="small">语音</el-option>
          <el-option label="文字" value="1" size="small">文字</el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="是否置顶;0.不置顶，1.置顶" prop="isTop">
        <el-input v-model="dataForm.isTop" placeholder="是否置顶;0.不置顶，1.置顶"  size="small"></el-input>
      </el-form-item> -->
      <el-form-item label="排序" prop="sort">
        <el-input v-model="dataForm.sort" placeholder="排序"  size="small"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/message'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
          userId: '',
          userName: '',
          content: '',
          type: '',
          isTop: '',
          sort: ''
        },
        dataRule: {
          userName: [
            { required: true, message: '留言人不能为空', trigger: 'blur' }
          ],
          type: [
            { required: true, message: '类型;0.语音，1.文字不能为空', trigger: 'blur' }
          ],
          sort: [
            { required: true, message: '排序不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.userId = data.message.userId
                this.dataForm.userName = data.message.userName
                this.dataForm.content = data.message.content
                this.dataForm.type = data.message.type
                this.dataForm.isTop = data.message.isTop
                this.dataForm.sort = data.message.sort
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'userId': this.dataForm.userId,
              'userName': this.dataForm.userName,
              'content': this.dataForm.content,
              'type': this.dataForm.type,
              'isTop': this.dataForm.isTop,
              'sort': this.dataForm.sort
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
