<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="姓名" prop="name">
        <el-input v-model="dataForm.name" placeholder="姓名"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="学级" prop="typeId">
        <el-select v-model="dataForm.typeId" size="small" placeholder="请选择学级"
          style="width: 100%" @change="typeIdChange()">
          <el-option v-for="item in typeList" :key="item"
            :label="item.name" :value="item.gradeId" size="small"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="年份" prop="yearId">
        <el-select v-model="dataForm.yearId" size="small" placeholder="请选择年份"
          style="width: 100%" @change="yearIdChange()">
          <el-option v-for="item in yearList" :key="item"
            :label="item.name" :value="item.gradeId" size="small"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="班级" prop="classId">
        <el-select v-model="dataForm.classId" size="small" placeholder="请选择班级"
          style="width: 100%">
          <el-option v-for="item in classList" :key="item"
            :label="item.name" :value="item.gradeId" size="small"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="照片" prop="picUrl">
        <el-upload
          style="display: inline-block; margin-right: 10px"
          class="avatar-uploader"
          :action="action"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          >
          <div v-if="dataForm.picUrl">
            <i v-if="dataForm.picUrl" color="#000"></i>
            <img
              v-if="
                dataForm.picUrl.toString().indexOf('.mp4') == -1 &&
                dataForm.picUrl.toString().indexOf('.MP4') == -1
              "
              :src="dataForm.picUrl"
              class="avatar"
            />
            <video
              v-else
              controls="controls"
              :src="dataForm.picUrl"
              style="width: 178px; height: auto"
            />
          </div>
          <i v-else class="el-icon-plus avatar-uploader-icon" title="点击上传" ></i>
        </el-upload>
        <div>
          <span>支持jpg,png ，尺寸285*200，文件小于500KB</span>
        </div>
      </el-form-item>
      <el-form-item label="简介" prop="summary">
        <el-input v-model="dataForm.summary" placeholder="简介"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input v-model="dataForm.sort" placeholder="排序"  size="small"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/student'
  import {getToken} from '@/utils/auth'
  import { queryGradeList } from '@/api/modules/grade';
  export default {
    data () {
      return {
        action: `${process.env.VUE_APP_BASE_API}/sys/oss/upload?token=${getToken()}`,
        visible: false,
        dataLoading: false,
        typeList:[],
        yearList:[],
        classList:[],
        dataForm: {
          id: 0,
          name: '',
          typeId: '',
          yearId: '',
          classId: '',
          picUrl: '',
          summary: '',
          sort: ''
        },
        dataRule: {
          name: [
            { required: true, message: '姓名不能为空', trigger: 'blur' }
          ],
          typeId: [
            { required: true, message: '学级不能为空', trigger: 'blur' }
          ],
          yearId: [
            { required: true, message: '年份不能为空', trigger: 'blur' }
          ],
          classId: [
            { required: true, message: '班级不能为空', trigger: 'blur' }
          ],
          sort: [
            { required: true, message: '排序不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.name = data.student.name
                this.dataForm.typeId = data.student.typeId
                this.dataForm.yearId = data.student.yearId
                this.dataForm.classId = data.student.classId
                this.dataForm.picUrl = data.student.picUrl
                this.dataForm.summary = data.student.summary
                this.dataForm.sort = data.student.sort

                var params1 = {
                  "type":"0"
                }
                queryGradeList(params1).then(({data}) => {
                  if (data) {
                    this.typeList = data
                  }
                })
                var params2 = {
                  "type":"1",
                  "parentId":this.dataForm.typeId
                }
                queryGradeList(params2).then(({data}) => {
                  if (data) {
                    this.yearList = data
                  }
                })
                var params3 = {
                  "type":"2",
                  "parentId":this.dataForm.yearId
                }
                queryGradeList(params3).then(({data}) => {
                  if (data) {
                    this.classList = data
                  }
                })
              }
            })
          } else {
            var params = {
              "type":"0"
            }
            queryGradeList(params).then(({data}) => {
              if (data) {
                this.typeList = data
               }
            })
          }
        })
      },
      typeIdChange(){
        this.dataForm.classId = "";
        this.dataForm.yearId = "";
        this.yearList = [];
        this.classList = [];
        var params = {
          "type":"1",
          "parentId":this.dataForm.typeId
        }
        queryGradeList(params).then(({data}) => {
          if (data) {
            this.yearList = data
          }
        })
      },
      yearIdChange(){
        this.dataForm.classId = "";
        this.classList = [];
        var params = {
          "type":"2",
          "parentId":this.dataForm.yearId
        }
        queryGradeList(params).then(({data}) => {
          if (data) {
            this.classList = data
          }
        })
      },
      handleAvatarSuccess(res, file, type) {
        if (res && res.code === 0) {
          this.dataForm.picUrl = res.data.fileUrl
        } else {
            this.$message.error(res.msg);
        }
      },
      beforeAvatarUpload(file) {
        const isJPG =
            file.type === "image/jpeg" ||
            file.type === "image/bmp" ||
            file.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isJPG) {
            this.$message.error("上传格式不支持!");
        }
        if (!isLt2M) {
            this.$message.error("上传文件大小不能超过 5MB!");
        }
        return isJPG && isLt2M;
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'name': this.dataForm.name,
              'typeId': this.dataForm.typeId,
              'yearId': this.dataForm.yearId,
              'classId': this.dataForm.classId,
              'picUrl': this.dataForm.picUrl,
              'summary': this.dataForm.summary,
              'sort': this.dataForm.sort
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
<style>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        display: block;
    }
    .avatar-uploader .el-upload-dragger{
      width: 178px;
      height: 178px;
    }
</style>